@charset 'UTF-8';
@import "/Users/alivevn/Website/vhost/cme/src/scss/utility/_burger.scss";
@import "/Users/alivevn/Website/vhost/cme/src/scss/utility/_flex.scss";
@import "/Users/alivevn/Website/vhost/cme/src/scss/utility/_media-queries.scss";
@import "/Users/alivevn/Website/vhost/cme/src/scss/utility/_mixin.scss";

.top {
	&__mainvisual {
		position: relative;
		overflow: hidden;
		@include SP {
			position: static;
		}
		&__block-img {
			@include SP {
				position: relative;
			}
		}
		&--main-img {
			&:before {
				content: '';
				background: url('../../img/top/bg_line_main.png') 0 0/cover no-repeat;
				width: 100%;
				height: 100%;
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 3;
				pointer-events: none;
				opacity: .5;
				@include SP {
					background-image: url('../../img/top/bg_line_main_sp.png');
				}
			}
			.slider {
				background: rgb(0,160,160);
				background: -moz-linear-gradient(315deg, rgba(0,160,160,1) 0%, rgba(0,54,130,1) 100%);
				background: -webkit-linear-gradient(315deg, rgba(0,160,160,1) 0%, rgba(0,54,130,1) 100%);
				background: linear-gradient(315deg, rgba(0,160,160,1) 0%, rgba(0,54,130,1) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00a0a0",endColorstr="#003682",GradientType=1);
				height: 100vh;
				li {
					height: 100vh;
					img {
						width: 100%;
						display: block;
						object-fit: cover;
						height: 100%;
					}
				}
				.owl-dots {
					position: absolute;
					bottom: 140px;
					left: 67px;
					width: 35px;
					z-index: 9999;
					counter-reset: dots;
					@include min-screen(768px) {
						left: 20px;
						bottom: 90px;
					}
					@include min-screen(1200px) {
						bottom: 140px;
						left: 67px;
					}
					@include SP {
						left: 20px;
						bottom: 20px;
					}
					button.owl-dot {
						width: 35px;
						height: 35px;
						border: 1px solid #fff;
						@include border-radius(100%);
						position: relative;
						opacity: .3;
						@include transition_c(0.5s);
						@include SP {
							width: 26px;
							height: 26px;
						}
						&:not(:last-child) {
							margin: 0 0 20px 0;
							&:before {
								content: '';
								width: 1px;
								height: 20px;
								position: absolute;
								left: 50%;
								margin-left: -1px;
								bottom: -20px;
								background: #fff;
								opacity: .3;
								@include transition_c(0.5s);
							}
						}
						span {
							&:before {
								counter-increment:dots;
							    content: counter(dots);
							    color: #fff;
							    @include font-size(15);
							    line-height: 33px;
							    @include SP {
							    	@include font-size(11);
							    	line-height: 24px;
							    }
							}
						}
						&.active {
							opacity: 1;
							&:not(:last-child) {
								&:after {
									content: '';
									width: 1px;
									height: 10px;
									position: absolute;
									left: 50%;
									margin-left: -1px;
									bottom: -10px;
									background: #fff;
									opacity: 1;
									@include transition_c(0.5s);
								}
							}
						}
					}
				}
			}
		}
		&--title {
			@include flexbox();
			@include flex-direction(column);
			@include align-items(center);
			@include justify-content(center);
			color: #fff;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			width: 100%;
			z-index: 3;
			padding: 15px;
			pointer-events: none;
			.tit {
				@include font-line-spacing(60,78,40);
				font-weight: 600;
				text-transform: uppercase;
				text-align: center;
				@include min-screen(768px) {
					@include font-line-spacing(40,58,40);
				}
				@include min-screen(992px) {
					@include font-line-spacing(60,78,40);
				}
				@include SP {
					@include font-line-spacing(50,56,40);
					text-align: center;
				}
				@include max-screen(360px) {
					@include font-line-spacing(40,46,40);
				}
				span {
					@include font-line-spacing(22,36,40);
					font-weight: 500;
					text-align: center;
					text-transform: capitalize;
					margin: 14px 0 0;
					display: block;
					@include min-screen(768px) {
						@include font-line-spacing(18,32,40);
					}
					@include min-screen(992px) {
						@include font-line-spacing(22,36,40);
					}
					@include SP {
						@include font-line-spacing(22,30);
						margin: 25px 0 0;
					}
					@include max-screen(360px) {
						@include font-line-spacing(18,26);
					}
				}
			}
		}
		&--news {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			z-index: 4;
			background: rgb(0,54,130);
			background: -moz-linear-gradient(90deg, rgba(0,54,130,1) 0%, rgba(0,26,62,1) 100%);
			background: -webkit-linear-gradient(90deg, rgba(0,54,130,1) 0%, rgba(0,26,62,1) 100%);
			background: linear-gradient(90deg, rgba(0,54,130,1) 0%, rgba(0,26,62,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#003682",endColorstr="#001a3e",GradientType=1);
			padding: 22px 0;
			@include SP {
				position: static;
				padding: 24px 0;
			}
			.box-news {
				@include flexbox();
				@include align-items(center);
				@include justify-content(space-between);
				@include SP {
					@include flex-direction(column);
					@include align-items(flex-start);
				}
				&--title {
					width: 24.444%;
					padding: 0 15px 0 0;
					@include flex(0 0 auto);
					@include SP {
						width: 100%;
						text-align: center;
						padding: 0;
					}
					.ttl {
						color: #fff;
						@include font-line-spacing(25,33,20);
						font-weight: 500;
						text-transform: uppercase;
						@include min-screen(768px) {
							@include font-line-spacing(17,26,20);
						}
						@include min-screen(992px) {
							@include font-line-spacing(21,29,20);
						}
						@include min-screen(1200px) {
							@include font-line-spacing(25,33,20);
						}
					}
				}
				&--content {
					padding: 0 24px 0 0;
					@include flex(1);
					@include SP {
						padding: 21px 0 0;
					}
					.item {
						@include flexbox();
						@include align-items(center);
						.desktop.windows.ie &,
						.tablet.windows.ie & {
							display: block;
							display: -webkit-box;
							@include clamp(1);
							white-space: nowrap;
						}
						@include SP {
							@include flex-direction(column);
							@include align-items(flex-start);
						}
						&-des {
							@include flexbox();
							@include align-items(center);
							.desktop.windows.ie &,
							.tablet.windows.ie & {
								width: 210px;
								float: left;
							}
							.date {
								color: #fff;
								@include font-line-spacing(13,30,20);
								margin: 0 24px 0 0;
								@include flex( 0 0 auto);
								@include min-screen(768px) {
									@include font-line-spacing(12,30,20);
									margin: 0 14px 0 0;
								}
								@include min-screen(992px) {
									@include font-line-spacing(13,30,20);
									margin: 0 24px 0 0;
								}
								@include SP {
									margin: 0 10px 0 0;
								}
							}
							.cate {
								font-size: 0;
								line-height: 0;
								margin: -3px 24px 0 0;
								max-width: 100px;
								width: 100%;
								@include min-screen(768px) {
									margin: -3px 14px 0 0;
								}
								@include min-screen(992px) {
									margin: -3px 24px 0 0;
								}
								@include SP {
									margin: 0 10px 0 0;
									@include flex(1);
									max-width: 100%;
								}
								span {
									color: #fff;
									@include font-line-spacing(11,21,20);
									font-weight: 500;
									background: #003682;
									text-align: center;
									display: inline-block;
									padding: 5px;
									width: 100%;
									margin: 3px 3px 0 0;
									@include SP {
										@include font-line-spacing(13,21,20);
										padding: 5px 10px;
									}
									.desktop.windows.ie &,
									.tablet.windows.ie & {
										width: initial;
									}
								}
							}
						}
						.tit {
							@include flex(1);
							@include SP {
								margin: 14px 0 0;	
							}
							a {
								display: block;
								color: #fff;
								@include font-line-spacing(15,23,20);
								@include clamp(1);
								.desktop.windows.ie &,
								.tablet.windows.ie & {

								}
								@include min-screen(768px) {
									@include font-line-spacing(13,23,20);
								}
								@include min-screen(992px) {
									@include font-line-spacing(15,23,20);
								}
								@include SP {
									@include clamp(2);
								}
								@include max-screen(360px) {
									@include font-line-spacing(14,22,20);
								}
								&:hover {
									color: #FFA515;
									opacity: 1;
								}
							}
						}
					}
				}
				&--button {
					width: 110px;
					@include flex(0 0 auto);
					@include SP {
						@include align-self(flex-end);
						margin: 14px 0 0;
					}
					a {
						display: inline-block;
						color: #fff;
						text-transform: uppercase;
						@include font-line-spacing(15,23,100);
						font-weight: 500;
						@include min-screen(768px) {
							@include font-line-spacing(13,23,100);
						}
						@include min-screen(992px) {
							@include font-line-spacing(15,23,100);
						}
						&:hover {
							opacity: 1;
							color: #FFA515;
						}
					}
				}
			}
		}
	}
	&__service {
		background: url('../../img/common/other/bg_texture.jpg') 0 0 repeat;
		padding: 120px 0;
		@include SP {
			padding: 40px 0;
		}
		&__blocks {
			padding: 68px 0 0;
			@include SP {
				padding: 40px 0 0;
			}
			&--item {
				position: relative;
				@include clearfix();
				@include SP {
					margin: 0 -15px;
				}
				&:not(:last-child) {
					margin: 0 0 120px;
					@include SP {
						margin: 0 -15px 120px;
					}
				}
				.img {
					width: 55.6%;
					text-align: right;
					position: absolute;
					right: 0;
					height: 100%;
					overflow: hidden;
					@include SP {
						position: static;
						width: 100%;
						text-align: center;
					}
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
						.desktop.windows.ie &,
						.tablet.windows.ie & {
							height: auto;
						}
						@include SP {
							height: auto;
						}
					}
				}
				.box-content {
					max-width: 722px;
					width: 100%;
					float: left;
					padding: 47px 0 17px;
					position: relative;
					z-index: 1;
					@include min-screen(768px) {
						max-width: 522px;
					}
					@include min-screen(992px) {
						max-width: 722px;
					}
					@include SP {
						max-width: 100%;
						margin: -40px 0 0 16px;
						padding: 0;
						float: none;
					}
					&--ctn {
						padding: 68px 72px;
						background: #fff;
						min-height: 472px;
						@include box-shadow(20px, 20px, 90px, rgba(0,0,0,0.15));
						@include SP {
							padding: 72px 48px 72px 32px;
							min-height: auto;
						}
						.cmn-sub-tit {
							margin: 0 0 26px;
						}
					}
					.cmn-btn {
						margin: -32px 0 0 72px;
						@include SP {
							margin: -32px 0 0 40px;
							max-width: 262px;
						}
						@include max-screen(360px) {
							max-width: 220px;
						}
					}
				}
				&:nth-child(2n) {
					.img {
						text-align: left;
						left: 0;
						@include SP {
							text-align: center;
						}
					}
					.box-content {
						float: right;
						@include SP {
							float: none;
						}
					}
				}
			}
		}
	}
	&__product {
		position: relative;
		&:before {
			content: '';
			background: url('../../img/common/other/bg01.png') 0 0/100% auto no-repeat;
			position: absolute;
			top: 0;
			right: 0;
			width: 100%;
			height: 100%;
			z-index: -1;
			pointer-events: none;
			@include SP {
				background: url('../../img/common/other/bg01_sp1.png') 0 0/100% auto no-repeat;
			}
		}
	}
	&__company {
		background: url('../../img/common/other/bg_texture.jpg') 0 0/cover no-repeat;
		padding: 120px 0;
		&__blocks {
			.block-item {
				@include box-shadow(20px, 20px, 90px, rgba(0,0,0,0.15));
				@include border-radius(140px 0 0 0);
				&-wrap {
					@include flexbox();
					@include flex-wrap(wrap);
					position: relative;
					background: #fff;
					-webkit-clip-path: polygon(7% 0, 100% 0, 100% 100%, 0 100%, 0% 13%);
					clip-path: polygon(7% 0, 100% 0, 100% 100%, 0 100%, 0% 13%);
					margin: 64px 0 0;
					@include SP {
						clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%, 0% 4%);
						-webkit-clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%, 0% 4%);
					}
				}
				&--info {
					width: 42.6%;
					padding: 72px 20px 58px 72px;
					position: relative;
					z-index: 1;
					@include min-screen(768px) {
						padding: 50px 20px 50px 50px;
						width: 50%;
					}
					@include min-screen(992px) {
						padding: 72px 20px 58px 72px;
						width: 42.6%;
					}
					@include SP {
						width: 100%;
						padding: 40px;
					}
					@include max-screen(360px) {
						padding: 30px;
					}
					.list-access {
						dt {
							font-weight: 600;
							@include font-line-spacing(15,23,30);
							text-transform: uppercase;
							@include max-screen(360px) {
								@include font-line-spacing(14,22,20);
							}
						}
						dd {
							@include font-line-spacing(15,23,30);
							margin: 5px 0 20px;
							@include SP {
								margin: 5px 0 12px;
							}
							@include max-screen(360px) {
								@include font-line-spacing(14,22,20);
							}
						}
					}
				}
				&--map {
					width: 57.4%;
					position: relative;
					z-index: 1;
					@include min-screen(768px) {
						width: 50%;
					}
					@include min-screen(992px) {
						width: 57.4%;
					}
					@include SP {
						width: 100%;
						height: 200px;
					}
					.iframe-map {
						height: 100%;
					}
				}
			}
		}
	}
	&__recruit {
		padding: 70px 0;
		background: url('../../img/common/other/bg02.png') center top/cover no-repeat;
		@include SP {
			background: url('../../img/common/other/bg02_sp.png') center top/cover no-repeat;
		}
		.cmn-title {
			color: $colortext;
		}
		.cmn-txt {
			text-align: center;
			margin: 40px 0 0;
		}
		.cmn-btn {
			margin: 40px auto 0;
		}
	}
}